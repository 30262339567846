import { FC, useMemo } from 'react';
import FileService from '../../../../services/FileService';
import { FileInfo, LinkInfo } from '../../../shared/file-upload/FileInfo';
import { FileUploadType } from '../../../shared/file-upload/FileuploadType';
import ExternalLinkIcon from '../../../shared/icon/ExternalLink';
import ActionPreview from '../../ActionPreview';
import ActionBaseProps from '../ActionBaseProps';
import { FileOrLinkUploadResponse } from './FileOrLinkUploadAction';
import { DocumentResponse } from '../../../../models/Document';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { FormType } from '../../../../models/FormTypes';
import StringUtils from '../../../../utils/StringUtils';

const FileOrLinkUploadPreview: FC<ActionBaseProps<FileOrLinkUploadResponse>> = (props) => {
  const { response, data } = props;
  const { question, previewDescription, previewQuestion } = data;

  const { clientForm } = useFormRendererInfo();
  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);
  const answer = useMemo(() => {
    if (!response) {
      return '-';
    }

    const getTitle = (file: FileInfo | LinkInfo) => {
      if (clientForm && clientForm.type === FormType.Document && clientForm.attachments && response.type === FileUploadType.File) {
        const fileIndex = clientForm.attachments.findIndex((x) => x.fileId === (file as FileInfo).id);
        if (fileIndex !== undefined) {
          const documentResponse = clientForm as DocumentResponse;
          return `${StringUtils.makePrefixWithNumber(
            documentResponse.prefix,
            documentResponse.documentNumber,
            documentResponse.templateModuleTranslations,
          )}-${StringUtils.addLeadingZeros(fileIndex + 1)}-${file.name}`;
        }
      }
      return file.name;
    };

    const openFile = () => {
      if (response.type === FileUploadType.File) {
        FileService.getFile((response.fileInfo as FileInfo).id).then((fileBlob) => {
          const url = URL.createObjectURL(fileBlob);

          const link = document.createElement('a');
          link.target = '_blank';
          link.href = url;
          link.click();

          setTimeout(() => {
            URL.revokeObjectURL(url);
          }, 100);
        });
      } else {
        const url = response.fileInfo?.url?.match(/^https?:\/\//i) ? response.fileInfo?.url : `http://${response.fileInfo?.url}`;

        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.click();
      }
    };

    return (
      <div className="cursor-pointer" onClick={openFile}>
        <span className="underline">{response.fileInfo && getTitle(response.fileInfo)}</span> <ExternalLinkIcon className="h-6 w-6" />
      </div>
    );
  }, [clientForm, response]);

  return <ActionPreview data-cy="file-or-link-preview" question={title} description={previewDescription} answer={answer} />;
};

export default FileOrLinkUploadPreview;
